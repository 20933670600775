// src/ChampionGuide.js
import React from "react";
import { Link } from "react-router-dom";
import ImageGallery from "./ImageGallery";

function ChampionGuide() {
  return (
    <div>

      <ImageGallery />
    </div>
  );
}

export default ChampionGuide;
